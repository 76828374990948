<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="trackingID">
                {{ sprintf($t('pages.module.tracking.save.editTracking'), [tracking.name ?? '']) }}
            </template>
            <template v-else>{{ $t('pages.module.tracking.save.newTracking') }}</template>
        </h2>

        <router-link v-if="!$root.filterWithUrl" to="/module/tracking-code" class="btn btn-primary align-self-center ms-4 back-page-btn">
            <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.tracking.title") }}
        </router-link>
    </div>

    <el-form :model="form.data" ref="trackingForm">
        <div class="row">
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.tracking.cols.name') }}</label>
                            <el-form-item prop="name" :rules="$validation.getMessage(['required'])">
                                <el-input v-model="form.data.name" type="text"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.tracking.save.cols.content') }}</label>
                            <el-form-item class="code-mirror-form-item" prop="content" :rules="$validation.getMessage(['required'])">
                                <el-input v-model="form.data.content" type="hidden"/>
                                <Codemirror class="code-mirror" v-model:value="form.data.content" :options="cmOptions" :height="300"/>
                            </el-form-item>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <custom-table
                            :runModeServer="false"
                            :title="$t('pages.module.tracking.save.visibility.show.title')"
                            :subTitle="$t('pages.module.tracking.save.visibility.show.subTitle')"
                            class="mt-7"
                            selectableRows
                            :items="form.visibility.show"
                            :columns="visibility.fields"
                            :actions="visibility.actions"
                            :pagination="visibility.show.pagination"
                            @selectableRows="(record) => { return this.handleVisibilitySelectedRow('show', record); }"
                            @action="(name) => { return this.handleVisibilityClickAction('show', name); }">
                            <template v-slot:type="{ row: record }">
                                {{ linkTypesObj[record.type] ? linkTypesObj[record.type].name : "-" }}
                            </template>
                            <template v-slot:assigned="{ row: record }">
                                <span v-if="record.type_id == -1">{{ $t("pages.module.tracking.save.visibility.all") }}</span>
                                <span v-else>{{ linkTypesObj[record.type] && linkTypesObj[record.type].assigned[record.type_id] ? linkTypesObj[record.type].assigned[record.type_id].title : "-" }}</span>
                            </template>
                            <template v-slot:actions="{ row: record }">
                                <div class="d-flex justify-content-end">
                                    <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteVisibilityRecord('show',[record])">
                                        <template #reference>
                                            <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                                <span class="svg-icon svg-icon-3">
                                                    <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                                </span>
                                            </a>
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </template>
                        </custom-table>
                    </div>
                    <div class="col-md-6">
                        <custom-table
                            :runModeServer="false"
                            :title="$t('pages.module.tracking.save.visibility.hide.title')"
                            :subTitle="$t('pages.module.tracking.save.visibility.hide.subTitle')"
                            class="mt-7"
                            selectableRows
                            :items="form.visibility.hide"
                            :columns="visibility.fields"
                            :actions="hideActions"
                            :pagination="visibility.hide.pagination"
                            @selectableRows="(record) => { return this.handleVisibilitySelectedRow('hide', record); }"
                            @action="(name) => { return this.handleVisibilityClickAction('hide', name); }">
                            <template v-slot:type="{ row: record }">
                                {{ linkTypesObj[record.type] ? linkTypesObj[record.type].name : "-" }}
                            </template>
                            <template v-slot:assigned="{ row: record }">
                                <span v-if="record.type_id == -1">{{ $t("pages.module.tracking.save.visibility.all") }}</span>
                                <span v-else>{{ linkTypesObj[record.type] && linkTypesObj[record.type].assigned[record.type_id] ? linkTypesObj[record.type].assigned[record.type_id].title : "-" }}</span>
                            </template>
                            <template v-slot:actions="{ row: record }">
                                <div class="d-flex justify-content-end">
                                    <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteVisibilityRecord('hide',[record])">
                                        <template #reference>
                                            <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                                <span class="svg-icon svg-icon-3">
                                                    <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                                                </span>
                                            </a>
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </template>
                        </custom-table>
                    </div>
                </div>
            </div>

            <div class="col-md-3 mt-7 mt-md-0">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.tracking.cols.location') }}</label>
                            <el-form-item prop="location_id" :rules="$validation.getMessage(['required'])">
                                <el-select v-model="form.data.location_id" class="w-100" :placeholder="$t('common.chooseSelect')">
                                    <el-option v-for="(location, locationIndex) in locations" :key="locationIndex" :value="location.id" :label="location.name"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                            <el-form-item prop="sort" :rules="$validation.getMessage(['required'])">
                                <el-input-number v-model="form.data.sort" :min="1"/>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                            <el-form-item prop="active">
                                <el-radio-group v-model="form.data.active">
                                    <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                    <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0">
                                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>

    <div class="modal fade" id="kt_modal_visibility" ref="visibilityModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ visibility.form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onVisibilitySubmit()" :model="visibility.form.data" ref="visibilityForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.tracking.save.visibility.cols.type') }}</label>
                                <el-form-item prop="type" :rules="$validation.getMessage(['required'])">
                                    <el-select v-model="visibility.form.data.type" class="w-100" :placeholder="$t('common.chooseSelect')" @change="selectedLinkType">
                                        <el-option v-for="(type, typeIndex) in this[this.visibility.form.type + 'LinkTypes']" :key="type.id" :value="type.code" :label="type.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7" v-if="visibility.form.selectedType.assigned && visibility.form.selectedType.assigned.length">
                                <label class="fs-6 fw-bold mb-2" :class="{'required': visibility.form.selectedType.assignedRequired}">
                                    {{ $t('pages.module.tracking.save.visibility.cols.assigned') }}
                                    <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.module.tracking.save.visibility.informationBoxes.'+visibility.form.type+'Assigned')" placement="top">
                                        <span class="svg-icon svg-icon-1">
                                            <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                        </span>
                                    </el-tooltip>
                                </label>
                                <el-form-item prop="assigned" :rules="visibility.form.selectedType.assignedRequired && $validation.getMessage(['required'])">
                                    <el-select v-model="visibility.form.data.assigned" class="w-100" :placeholder="$t('common.chooseSelect')" multiple clearable>
                                        <el-option v-for="(item, itemIndex) in visibility.form.selectedType.assigned" :key="itemIndex" :value="item.id" :label="item.title"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="visibility.form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="visibility.form.loading">
                            <span v-if="!visibility.form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="visibility.form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
 </template>

<script>
import CustomTable from "@/components/custom-table";

import "codemirror/mode/javascript/javascript.js";
import "codemirror/theme/base16-dark.css";
import 'codemirror/keymap/sublime';

export default {
    name: "_id",
    components: {
        CustomTable
    },
    data() {
        return {
            form: {
                updateStatus: false,
                loading: false,
                visibility: {
                    show: [],
                    hide: []
                },
                data: {
                    visibility: null,
                    sort: 1,
                    active: true
                }
            },
            tracking: {},
            visibility: {
                fields: [
                    {
                        name: this.$t("pages.module.tracking.save.visibility.cols.type"),
                        scopedSlots: {customRender: "type"},
                        class: ""
                    },
                    {
                        name: this.$t("pages.module.tracking.save.visibility.cols.assigned"),
                        scopedSlots: {customRender: "assigned"},
                        class: ""
                    },
                    {
                        name: this.$t("common.action"),
                        key: "action",
                        scopedSlots: {customRender: "actions"}
                    }
                ],
                actions: [
                    {
                        name: "new",
                        icon: "bi-plus-lg",
                        label: "btn.add"
                    },
                    {
                        name: "delete",
                        icon: "bi-trash",
                        label: "btn.delete"
                    }
                ],
                show: {
                    allCodes: [],
                    specialCodes: [],
                    selectedRowKeys: [],
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: 0,
                    }
                },
                hide: {
                    allCodes: [],
                    specialCodes: [],
                    selectedRowKeys: [],
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: 0,
                    }
                },
                form: {
                    title: '',
                    loading: false,
                    selectedType: {},
                    data: {}
                }
            },
            linkTypes: [],
            linkTypesObj: {},
            specialLinkTypes: [
                {
                    id: -2,
                    code: "404",
                    name: this.$t('pages.module.tracking.save.visibility.specialLinkTypes.page404'),
                    assigned: []
                },
                {
                    id: -1,
                    code: "500",
                    name: this.$t('pages.module.tracking.save.visibility.specialLinkTypes.page500'),
                    assigned: []
                },
                {
                    id: 0,
                    code: "ROOT",
                    name: this.$t('common.homePage'),
                    assigned: []
                }
            ],
            locations: [],
            cmOptions: {
                tabSize: 4,
                styleActiveLine: true,
                lineNumbers: true,
                line: true,
                foldGutter: true,
                styleSelectedText: true,
                mode: "text/javascript",
                keyMap: "sublime",
                matchBrackets: true,
                showCursorWhenSelecting: true,
                theme: "base16-dark",
                extraKeys: { Ctrl: "autocomplete" },
                hintOptions: {
                    completeSingle: false
                }
            }
        }
    },
    computed: {
        trackingID() {
            return this.$route.params.id;
        },
        showLinkTypes(){
            let types = [];

            if(this.linkTypes.length){
                this.cloneData(this.linkTypes).forEach((type) => {
                    if(this.visibility.hide.allCodes.includes(type.code) || this.visibility.show.specialCodes.includes(type.code)){
                        type.assignedRequired = true;
                    }

                    if(!this.visibility.show.allCodes.includes(type.code)){
                        types.push(type);
                    }
                });
            }

            return types;
        },
        hideLinkTypes(){
            let types = [];

            if(this.linkTypes.length){
                let showAllCodes = this.visibility.show.allCodes;

                this.cloneData(this.linkTypes).forEach((type) => {
                    if(showAllCodes.includes(type.code) || this.visibility.hide.specialCodes.includes(type.code)){
                        type.assignedRequired = true;
                    }

                    if(((!showAllCodes.length && !this.visibility.show.specialCodes.length) || showAllCodes.includes(type.code)) && !this.visibility.hide.allCodes.includes(type.code)){
                        types.push(type);
                    }
                });
            }

            return types;
        },
        hideActions(){
            let actions = [
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ];

            if (this.hideLinkTypes.length > 0) {
                actions.unshift({
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                });
            }

            return actions;
        }
    },
    created() {
        if (this.trackingID && !(this.trackingID > 0)) {
            this.$router.push({
                path: "/module/tracking-code"
            });
        } else {
            this.loadLinkTypes();
            this.loadLocations();
        }
    },
    mounted() {
        if (this.trackingID && this.trackingID > 0) {
            this.loadTracking();
        }
    },
    methods: {
        loadLocations(){
            this.axios.get(this.endpoints['module_tracking_location']).then(res => {
                if (res.data.status) {
                    this.locations = res.data.data;
                }
            });
        },
        loadTracking(trackingID = this.trackingID,) {
            this.axios.get(this.endpoints['module_tracking'] + '/' + trackingID).then((response) => {
                let data = response.data.data;

                if (data.visibility && data.visibility.show) {
                    this.form.visibility.show = data.visibility.show;
                    this.updateVisibilityPagination('show');
                    this.updateVisibilityCodes('show');
                }

                if (data.visibility && data.visibility.hide) {
                    this.form.visibility.hide = data.visibility.hide;
                    this.updateVisibilityPagination('hide');
                    this.updateVisibilityCodes('hide');
                }

                this.tracking = data;

                this.form.updateStatus = true;
                this.form.data = data;
            })
        },
        loadLinkTypes() {
            this.loadSkeleton = true;

            this.axios.get(this.endpoints['link_type']).then((response) => {
                if (response.data.status) {
                    let data = response.data.data;
                    let linkTypes = [];

                    linkTypes = data.filter((data) => data.code != 'EXTERNAL');

                    let multipleRequest = linkTypes.map((type) => {
                        return this.axios.get(this.endpoints['link_type_assigned'] + '/' + type.id, {
                            params: {
                                language_id: this.$root.defaultLanguage.id
                            }
                        })
                    });

                    this.requestLinkTypeAssigned(multipleRequest).then((response) => {
                        linkTypes = linkTypes.map((type, typeIndex) => {
                            type.assigned = response.data[typeIndex];
                            return type;
                        })

                        this.linkTypes = [...linkTypes, ...this.specialLinkTypes];

                        this.convertLinkTypeObject();

                        this.loadSkeleton = false;
                    }).catch((error) => {
                        this.$notify({
                            type: 'error',
                            title: this.$t("messages.error"),
                            message: error,
                        });
                    })
                }
            });
        },
        async requestLinkTypeAssigned(multipleRequest) {
            return new Promise((resolve, reject) => {
                this.axios.all(multipleRequest).then(this.axios.spread((...responses) => {
                    let result = [];

                    for (let i = 0; i < responses.length; i++) {
                        let handleResponse = responses[i].data;

                        if (!handleResponse.status) {
                            break;
                        }

                        result.push(handleResponse.data);
                    }

                    if (result.length == responses.length) {
                        resolve({status: true, data: result});
                    } else {
                        reject('request_count_dont_match_with_response_count');
                    }
                })).catch((errors) => {
                    reject('occurred_any_error');
                })
            });
        },
        convertLinkTypeObject() {
            this.linkTypes.forEach((type) => {
                this.linkTypesObj[type.code] = this.cloneData(type);
                this.linkTypesObj[type.code].assigned = {};

                type.assigned.forEach((assign) => {
                    this.linkTypesObj[type.code].assigned[assign.id] = assign;
                })
            });
        },
        onSubmit() {
            this.$refs.trackingForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;
                    let formData = this.prepareFormData();

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['module_tracking'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadTracking();
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            if(this.responseMessages[error.response.data.message]) {
                                error.response.data.message = this.$t('pages.module.tracking.save.responseMessages.' + this.responseMessages[error.response.data.message]);
                            }

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        this.axios.post(this.endpoints['module_tracking'], formData).then(response => {
                            this.onResponse(response.data, () => {
                                let trackingID = response.data.data.id;
                                this.$router.push({
                                    path: "/module/tracking-code/save/" + trackingID
                                });
                                this.loadTracking(trackingID);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            if(this.responseMessages[error.response.data.message]) {
                                error.response.data.message = this.$t('pages.module.tracking.save.responseMessages.' + this.responseMessages[error.response.data.message]);
                            }

                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        prepareFormData() {
            let formData = this.cloneData(this.form.data);
            let visibility = {};

            if (this.form.visibility.show.length) {
                visibility.show = this.form.visibility.show;
            }

            if (this.form.visibility.hide.length) {
                visibility.hide = this.form.visibility.hide;
            }

            formData.visibility = Object.keys(visibility).length ? visibility : null;

            return formData
        },
        handleVisibilityClickAction(type, name) {
            switch (name) {
                case "new":
                    this.visibility.form.type = type;
                    this.newVisibility();
                    break;

                case "delete":
                    this.deleteVisibilityRecord(type, this.visibility[type].selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleVisibilitySelectedRow(type, record) {
            this.visibility[type].selectedRowKeys = record;
        },
        newVisibility() {
            this.visibility.form.data = {};
            this.visibility.form.selectedType = {};
            this.visibility.form.title = this.$t("pages.module.tracking.save.visibility." + this.visibility.form.type + ".new");
            this.showModal(this.$refs.visibilityModal);
        },
        onVisibilitySubmit() {
            this.$refs.visibilityForm.validate((valid) => {
                if (valid) {
                    let formData = this.visibility.form.data;
                    let visibilityData = [{type: formData.type}];
                    let formType = this.visibility.form.type;

                    let specialLinkTypes = this.specialLinkTypes.map(type => type.code);

                    if (specialLinkTypes.includes(formData.type)) {
                        visibilityData[0].type_id = 0;
                    } else if (formData.assigned && formData.assigned.length) {
                        visibilityData = formData.assigned.map((item) => {
                            return {
                                type: formData.type,
                                type_id: item
                            }
                        })
                    } else {
                        visibilityData[0].type_id = -1;
                    }

                    let formVisibility = this.form.visibility[formType];
                    this.form.visibility[formType] = [...formVisibility, ...visibilityData];

                    this.hideModal(this.$refs.visibilityModal);
                    this.updateVisibilityPagination(formType);
                    this.updateVisibilityCodes(formType);

                    setTimeout(() => {
                        this.visibility.form.loading = false;
                    }, 0);
                } else {
                    return false;
                }
            });
        },
        deleteVisibilityRecord(type, record) {
            if (record.length == 0) {
                this.$message.warning(this.$t('messages.selectRow'))
                return;
            }

            let recordJson = JSON.stringify(record);

            this.form.visibility[type] = this.form.visibility[type].filter(x => recordJson.indexOf(JSON.stringify(x)) == -1);
            this.visibility[type].selectedRowKeys = this.visibility[type].selectedRowKeys.filter(x => !recordJson.indexOf(JSON.stringify(x)) == -1);

            this.updateVisibilityPagination(type);
            this.updateVisibilityCodes(type);
        },
        updateVisibilityPagination(type) {
            this.visibility[type].pagination = {
                current: 1,
                pageSize: 10,
                total: this.form.visibility[type].length
            }
        },
        selectedLinkType(){
            let foundType = this[this.visibility.form.type + 'LinkTypes'].filter(linkType => linkType.code == this.visibility.form.data.type);
            this.visibility.form.selectedType = foundType.length == 1 ? foundType[0] : {};
            this.visibility.form.data.assigned = [];
        },
        updateVisibilityCodes(type){
            let allCodes = [];
            let specialCodes = [];

            this.form.visibility[type].forEach((item) => {
                if(item.type_id < 1){
                    allCodes.push(item.type);
                } else {
                    specialCodes.push(item.type);
                }
            });

            this.visibility[type].allCodes = allCodes;
            this.visibility[type].specialCodes = specialCodes;
        }
    }
}
</script>

<style>
.back-page-btn {
    height: 40px;
    line-height: 1.25;
}
.code-mirror {
    line-height: 21px !important;
}
.code-mirror > *, .codemirror-container:not(.original-style) .CodeMirror, .codemirror-container:not(.original-style) .CodeMirror-merge-pane {
    font-family: monospace !important;
}

.code-mirror-form-item.is-error .el-form-item__error {
    padding-top: 0 !important;
    margin-top: -7px !important;
}
</style>